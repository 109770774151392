import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'
import 'amfe-flexible'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';
import VueI18n from 'vue-i18n'
import 'animate.css';
import { WOW } from 'wowjs';
import animated from 'wowjs/css/libs/animate.css';
import Chat from 'vue-beautiful-chat'

import MetaInfo from 'vue-meta-info'


Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueI18n)
Vue.use(Chat)

Vue.use(MetaInfo)

// Vue.use(animated)
// Vue.use(Antd);
// 创建全局实例-加载动画
Vue.prototype.$wow = new WOW({
  boxClass: 'wo', // 需要执行动画的元素的 class
  animateClass: 'animated', //animation.css 动画的 class
  offset: 0, // 距离可视区域多少开始执行动画
  mobile: true, // 是否在移动设备上执行动画
  live: false, // 异步加载的内容是否有效
});
// 中英文切换
const i18n=new VueI18n({
  locale:localStorage.getItem('languageSet')||'zh',   //从localStorage里获取用户中英文选择，没有则默认中文
  messages:{
      'zh':require('./components/language/zh'),
      'en':require('./components/language/en')
  }
})

new Vue({
  i18n,   //把 i18n 挂载到 vue 根实例上
  router,
  store,
  render: h => h(App),
  // 预渲染
//   mounted () {
//     document.dispatchEvent(new Event('render-event'))
// }
}).$mount('#app')
