import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/Home/Home.vue"),
      },
      {
        path: "/entering",
        name: "entering",
        component: () => import("@/views/EnteringDk/EnteringDk.vue"),
      },
      {
        path: "/product",
        name: "product",
        component: () => import("@/views/Product/Product.vue"),
      },
      {
        path: "/solution",
        name: "solution",
        component: () => import("@/views/Solution/Solution.vue"),
      },
      {
        path: "/factory",
        name: "factory",
        component: () => import("@/views/Factory/Factory.vue"),
      },
      // {
      //   path: "/case",
      //   name: "case",
      //   component: () => import("@/views/Case"),
      // },
      {
        path: "/service",
        name: "service",
        component: () => import("@/views/Service/Service.vue"),
      },
      {
        path: "/contact",
        name: "contact",
        component: () => import("@/views/contact/index.vue"),
      },
      // 走进多科下 详情
      // 公司介绍
      {
        path: "/gsjsDetail",
        name: "gsjsDetail",
        component: () => import("@/views/EnteringDk/details/gsjsDetail.vue"),
      },
      // 资质证书
      {
        path: "/certificateDetail",
        name: "certificateDetail",
        component: () => import("@/views/EnteringDk/details/certificateDetail.vue"),
      },
      //新闻中心  详情
      {
        path: "/newsDetail",
        name: "newsDetail",
        component: () => import("@/views/EnteringDk/details/newsDetail.vue"),
      },
      // 社会责任 详情
      {
        path: "/responDetail",
        name: "responDetail",
        component: () => import("@/views/EnteringDk/details/responDetail.vue"),
      },
      // 解决方案下 详情
      {
        path: "/programtDetail",
        name: "programtDetail",
        component: () => import("@/views/Solution/details/programtDetail.vue"),
      },
      // 数智工厂 四个详情
      //智能制造
      { 
        path: "/znzzDetail",
        name: "znzzDetail",
        component: () => import("@/views/Factory/details/znzzDetail.vue"),
      },
      //场景方案
      { 
        path: "/cjfaDetail",
        name: "cjfaDetail",
        component: () => import("@/views/Factory/details/cjfaDetail.vue"),
      },
      // 智慧能源
      { 
        path: "/zhnyDetail",
        name: "zhnyDetail",
        component: () => import("@/views/Factory/details/zhnyDetail.vue"),
      }, 
      // 智慧资产
      {
        path: "/zhzcDetail",
        name: "zhzcDetail",
        component: () => import("@/views/Factory/details/zhzcDetail.vue"),
      },
      //  产品中心下 详情
      //  设备类别详情
      {
        path: "/deviceDetails",
        name: "deviceDetails",
        component: () => import("@/views/Product/deviceDetails.vue"),
      },
      // 接收后台的详情页
      {
        path: "/productDetail",
        name: "productDetail",
        component: () => import("@/views/Product/details/productDetail.vue"),
      },
      // 智能包装
      {
        path: "/pznbzDetail",
        name: "pznbzDetail",
        component: () => import("@/views/Product/details/pznbzDetail.vue"),
      },
      // 异物检测
      {
        path: "/pywjcDetail",
        name: "pywjcDetail",
        component: () => import("@/views/Product/details/pywjcDetail.vue"),
      },
      // 金属检测
      {
        path: "/pjsjcDetail",
        name: "pjsjcDetail",
        component: () => import("@/views/Product/details/pjsjcDetail.vue"),
      },
      // 重量检测
      {
        path: "/pzljcDetail",
        name: "pzljcDetail",
        component: () => import("@/views/Product/details/pzljcDetail.vue"),
      },
      // 密封检测
      {
        path: "/pmfjcDetail",
        name: "pmfjcDetail",
        component: () => import("@/views/Product/details/pmfjcDetail.vue"),
      },
       // 机器人码垛
       {
        path: "/pjqrmDetail",
        name: "pjqrmDetail",
        component: () => import("@/views/Product/details/pjqrmDetail.vue"),
      },
       // 立式超声波洗瓶机
       {
        path: "/plscsDetail",
        name: "plscsDetail",
        component: () => import("@/views/Product/details/plscsDetail.vue"),
      },
        // 热风循环灭菌隧道烘箱
        {
          path: "/prfxhDetail",
          name: "prfxhDetail",
          component: () => import("@/views/Product/details/prfxhDetail.vue"),
        },
          // 罐装加塞机
          {
            path: "/pgzjsDetail",
            name: "pgzjsDetail",
            component: () => import("@/views/Product/details/pgzjsDetail.vue"),
          },
             // 轧盖机
             {
              path: "/pygjDetail",
              name: "pygjDetail",
              component: () => import("@/views/Product/details/pygjDetail.vue"),
            },
                // 灯检机
                {
                  path: "/pdjjDetail",
                  name: "pdjjDetail",
                  component: () => import("@/views/Product/details/pdjjDetail.vue"),
                },

                // 客户案例详情页
                //案例详情
                // {
                //   path: "/caseDetail",
                //   name: "caseDetail",
                //   component: () => import("@/views/Case/details/detail.vue"),
                // },
                // 肉制品
               {
                path: "/rzpDetail",
                name: "rzpDetail",
                component: () => import("@/views/Case/details/rzpDetail.vue"),
               },
               //烘焙类
               {
                path: "/hbDetail",
                name: "hbDetail",
                component: () => import("@/views/Case/details/hbDetail.vue"),
               },

    ],
  },
  {
    // 会匹配所有路径
    path: "*",
    name: "404",
    component: () => import("@/views/404"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ y: 0 }), //滚动到顶端
});

export default router;
